import TTUploader from 'tt-uploader';
import axios from 'axios'
console.log(process.env)
const TokenInfo = {
  token: ''
}
const ttUploader = new TTUploader({
  userId: '2100867672', //建议设置能识别用户的唯一标识id，用于上传出错时排查问题，不要传入非 ASCII编码
  appId: '598496', //在应用云平台申请的appid
  imageConfig: {
    serviceId: 'z8ialgywaa'  // 申请的图片服务id
  }
})
// axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/temporary_credentials`)
axios.get(`https://api.plugcustom.com.cn/api/temporary_credentials`)
.then(res => {
  res = res.data
  if (res.code === 200) {
    TokenInfo.token = res.data
    // console.log(TokenInfo.token)
    // console.log(JSON.stringify(TokenInfo.token), '--')
  }
})



function base64Img2Blob(code){
  // const blob = new Blob([file], {
  //   type: file.type
  // });
  // return blob
  let parts = code.split(';base64,');
  let contentType = parts[0].split(':')[1];
  let raw = window.atob(parts[1]);
  let rawLength = raw.length;

  let uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], {type: contentType});
}


/**
 * 批量上传
 * @param {Object} imgList 要上传的图片对象
 * @param {Object} temp 可选 上传对象对应的字节模板
 */
function batchUpdateImage(imgList, temp) {
  if (!TokenInfo.token) {
    console.error('上传失败，token不存在')
    return Promise.reject('上传失败，token不存在')
  }
  
  temp = temp || 'tplv-z8ialgywaa-image'
  return new Promise((resolve, reject) => {
    const fileKeyObj = {}
    let count = 0, // 计数
    total = 0, // 总数
    updateUrlList = {} // 上传之后的url
    const successCallback = () => {
      if (count === total) {
        resolve(updateUrlList)
      }
    }
    for(let i in imgList) {
      total++
      const fileKey = ttUploader.addFile({
        file: base64Img2Blob(imgList[i]),
        stsToken: TokenInfo.token,
        type : "image"
      })
      fileKeyObj[fileKey] = i
    }
    ttUploader.on('complete', res => {
      count++
      updateUrlList[fileKeyObj[res.key]] = res.uploadResult.ImageUri

      successCallback()
      // resolve(res.uploadResult.ImageUri)
    })
    ttUploader.on('error', err => {
      console.log('ttUploader error:', err)
      reject(err)
    })
    ttUploader.start()
  })
}

export { batchUpdateImage }

/**
 * 单个上传
 * @param {base64} file
 * @param {*} temp
 */
function addFile(file, temp = '', hasReturnSize = false) {
  if (!TokenInfo.token) {
    console.error('上传失败，token不存在')
    return Promise.reject('上传失败，token不存在')
  }
  if(!file) {
    console.error('上传失败，file不存在')
    return Promise.reject('上传失败，file不不存在')
  }
  temp = temp || 'tplv-z8ialgywaa-image'
  return new Promise((resolve, reject) => {
    
    ttUploader.addImageFile({
      file: base64Img2Blob(file),
      stsToken: TokenInfo.token,
    })
    ttUploader.on('complete', res => {
      const { ImageUri, ImageWidth, ImageHeight } = res.uploadResult
      let url = hasReturnSize ? {
        width: ImageWidth,
        height: ImageHeight,
        uri: ImageUri
      } : `${res.uploadResult.ImageUri}`

      resolve(url)
    })
    ttUploader.on('error', err => {
      console.log(err, '----error')
      reject(err)
    })
    ttUploader.start()
  })
}

export { addFile }
