export const loadImage = (base64: string) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => {
      resolve({
        width: image.width,
        height: image.height
      })
    }
    image.onerror = (err) => {
      console.log(base64, '--')
      console.log('error', err)
      reject(err)
    }
    image.src = base64
  })
}